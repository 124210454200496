// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'

import { RolesListType } from '../types'

export interface RolesPermissionState {
  rolesList: { data: []; totalCount: number; hasData: boolean }
  selectedRole: RolesListType
  isNavBlocked: boolean
  showNavigateOutDialog: boolean
  navOutPath: string
}

const initialState: RolesPermissionState = {
  rolesList: { data: [], totalCount: 0, hasData: false },
  selectedRole: { roleId: '', roleName: '', levelId: 0 },
  isNavBlocked: false,
  showNavigateOutDialog: false,
  navOutPath: '',
}

// slice
export const rolesPermissionSlice = createSlice({
  name: 'rolesPermission',
  initialState,
  reducers: {
    setRoleList: (state, action) => {
      state.rolesList = action.payload
    },
    setSeletedRole: (state, action) => {
      state.selectedRole = action.payload
    },
    setIsNavBlocked: (state, action) => {
      state.isNavBlocked = action.payload
    },
    setShowNavigateOutDialog: (state, action) => {
      state.showNavigateOutDialog = action.payload
    },
    setNavOutPath: (state, action) => {
      state.navOutPath = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const rolesPermissionActions = {
  setRoleList: rolesPermissionSlice.actions.setRoleList,
  setSelectedRole: rolesPermissionSlice.actions.setSeletedRole,
  setIsNavBlocked: rolesPermissionSlice.actions.setIsNavBlocked,
  setShowNavigateOutDialog: rolesPermissionSlice.actions.setShowNavigateOutDialog,
  setNavOutPath: rolesPermissionSlice.actions.setNavOutPath,
}

// Selectors
export const listOfRoles = (state: RootState) => state.roles.rolesList
export const selectedRole = (state: RootState) => state.roles.selectedRole
export const isNavBlocked = (state: RootState) => state.roles.isNavBlocked
export const showNavigateOutDialog = (state: RootState) => state.roles.showNavigateOutDialog
export const navOutPath = (state: RootState) => state.roles.navOutPath

// Reducer
export default rolesPermissionSlice.reducer
