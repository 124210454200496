// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from 'store/store'

export interface UserManagementState {
  userList: { data: []; totalCount: number; hasData: boolean }
  redirectTo: string
  selectedUserId: string
  refreshUserDets: boolean
}

const initialState: UserManagementState = {
  userList: { data: [], totalCount: 0, hasData: false },
  redirectTo: 'view-USER_LIST',
  selectedUserId: '',
  refreshUserDets: false,
}

// slice
export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload
    },
    setRedirectionTo: (state, action) => {
      state.redirectTo = action.payload
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload
    },
    setRefreshUserDets: state => {
      state.refreshUserDets = !state.refreshUserDets
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  },
})

// Actions
export const userManagementActions = {
  setUserList: userManagementSlice.actions.setUserList,
  setRedirectionTo: userManagementSlice.actions.setRedirectionTo,
  setSelectedUserId: userManagementSlice.actions.setSelectedUserId,
  setRefreshUserDets: userManagementSlice.actions.setRefreshUserDets,
}

// Selectors
export const listOfUsers = (state: RootState) => state.users.userList
export const redirectTo = (state: RootState) => state.users.redirectTo
export const getSelectedUserId = (state: RootState) => state.users.selectedUserId
export const getRefreshForUserDets = (state: RootState) => state.users.refreshUserDets

// Reducer
export default userManagementSlice.reducer
